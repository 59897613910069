export const careerHistory = [
  {
    name: "Bytro",
    logoUrl: "assets/career/bytro-logo.png",
    startDate: "SEP 2021",
    endDate: "AUG 2024",
    position: "Engineering Manager",
    summary: `Bytro is a gaming studio based in Hamburg, specialized in building web strategy games, and a part of a
    big swedish holding group called Stillfront. <br>
    <b>Responsibilities & Achievements:</b>
    ● Led and mentored a dynamic engineering team of 10 Engineers, defining growth plans and driving professional development. . <br>
    ● Conducted 25+ technical interviews, successfully hiring 3 engineers to expand the team. <br>
    ● Completed 70% of Engine 2, a framework to streamline the development of future-proof games. <br>
    ● Created a fully functional Design system (P17) for games, improving consistency and user experience across platforms. <br>
    ● Spearheaded a long-term plan to refactor legacy code, reducing technical debt and increasing maintainability. <br>
    ● Removed blockers and facilitated smooth communication within the team and with stakeholders. <br>
    ● Promoted 2 Engineers from Mid-Level to Senior, and 1 Engineer from Senior to Expert
    `,
  },
  {
    name: "Vidmob",
    logoUrl: "assets/career/vidmob-logo.png",
    startDate: "FEB 2020",
    endDate: "SEP 2021",
    position: "Lead Engineer",
    summary: `VidMob is a video content creation network and platform where brands can create digital video ads with
    expert teams. <br>
    <b>Responsibilities & Achievements:</b>
    ● Led a team of 5 Engineers from different regions/timezones in a fully remote setup. <br>
    ● Developed a framework to manage our collaboration style (PR reviews, delivery style, and code consistency) to achieve maximum 
    productivity for the whole team. <br>
    ● Successfully delivered our first phase of Vidmob design system with more than 60 different UI components, 20 reusable business 
    blocks, and 12 reusable reach hooks, for client-facing applications, ensuring scalability and design consistency. <br>
    ● Onboarded first 2 internal teams to use our design system. <br>
    ● Resolved over 150 issues between reported bugs, and UX enhancement. <br>
    `,
  },

  {
    name: "ShareNow",
    logoUrl: "assets/career/sn-logo.png",
    startDate: "FEB 2018",
    endDate: "FEB 2020",
    position: "Senior Web Engineer",
    summary: `ShareNow is a big venture of Daimler and BMW. Our goal is to push mobility towards sharing rather than buying. ShareNow is the result of
merging both car2go and drive now. <br>
    <b>Responsibilities & Achievements:</b>
    ● As a B2B Web Engineer our team successfully migrated 5000+ business account for both car2go and drive now. <br>
    ● Successfully onboarded new 380k users thus boosting our total system users to 3.5 Millions. <br>
    ● The first team to introduce serverless approach to our company, thus reducing infra costs by 60%. <br>
    ● Refactored our monolithic application into 70+ microservices using AWS Lambda. <br>
    ● Boosted our unit test coverage for all running apps from 30% to 92% (we tolerated 10% due to team capacity). <br>
    ● Built design system with more than 100 components for our user web/mobile applications Camelot). <br>
    ● Built design system for our internal applications/portals (Plattenbau). <br>
`,
  },
  {
    name: "Baddel",
    logoUrl: "assets/career/baddel-logo.png",
    startDate: "SEP 2016",
    endDate: "FEB 2018",
    position: "Director of Technology",
    summary: `Baddel was one of the first micro mobility platforms in middle east to offer e-bike rental service using your smartphone. We cover our
operation zones with stations where you could take the bike from any station and return it to the same or any other station. <br>
    <b>Responsibilities & Achievements:</b>
    ● Helped the business to technically go from ideation phase to production phase. <br>
    ● Hired and mentored full Engineering team. <br>
    ● Successfully delivered all essential system components (Infra, Server, Web, Backoffice, and Mobile). <br>
    ● Transferring embedded systems development in our system from 3rd party to be fully in house. <br>
    ● Reducing custom made embedded system components by ready made available ones, and thus reducing single station cost by 40%. <br>
    ● Successfully onboarded our first 3000 users in the first year. <br>
    ● Achieved first business milestone to complete 40k Trips over 2 years. <br>`,
  },
  {
    name: "Youxel",
    logoUrl: "assets/career/youxel-logo.png",
    startDate: "MAY 2014",
    endDate: "SEP 2016",
    position: "Product Lead Engineer",
    summary: `Youxel provides software solutions for our clients. First 1 year I was involved in service team, and later moved to lead a team working on a
product called Tremvo, which later became adam.ai. <br>
    <b>Responsibilities & Achievements:</b>
    ● Led, and mentored 15 Engineers between Backend, Frontend and QA. <br>
    ● Successfully delivered 5 client projects budgeted 2 Million SAR. <br>
    ● Hired 6 new Engineers to the team. <br>
    ● Migrated old core codebase from jQuery to Angular. <br>
    ● Delivered first successful MVP of Adam (the meeting robot). <br>
    `,
  },
  {
    name: "TechTank",
    logoUrl: "assets/career/techtank-logo.png",
    startDate: "JUN 2010",
    endDate: "MAY 2014",
    position: "Senior Software Engineer",
    summary: `
    <b>Responsibilities & Achievements:</b>
    ● Led the development of immersive AR and VR applications, creating interactive experiences for various industries, including gaming,
    education, and training. <br>
    ● Collaborated with UX/UI designers to create intuitive interfaces applications, conducting user testing and feedback sessions to
    improve usability and accessibility. <br>
    ● Implemented performance optimization strategies for web applications. <br>
    ● Developed and integrated RESTful APIs and WebSocket services to support real-time data communication between web applications
    and AR/VR environments. <br>
    ● Mentored junior engineers and interns in best practices for web development, fostering a culture of learning and innovation within the
    team.
    `,
  },
  {
    name: "M.E.R.E",
    logoUrl: "assets/career/mdepc-logo.png",
    startDate: "MAY 2008",
    endDate: "JUN 2010",
    position: "I&C Engineer",
    summary: `
    <b>Responsibilities & Achievements:</b>
    ● IT & software support for various applications, mainly the plant core sppa-t3000 developed by Siemens. <br>
    ● Integrate smart solutions that serve plant routines and frequent tasks. <br>
    ● Software support for GE Speedtronic Mark VIe and ABB symphony harmony. <br>
    ● Automation of Processes: Collaborated with senior engineers to automate routine tasks using scripting languages (e.g., Python,
    Shell), reducing manual workload and minimizing human error. <br>
    ● Version Control and Collaboration Tools: Gained experience using version control systems (e.g., Git) and collaboration tools (e.g.,
    JIRA, Confluence) to manage software projects effectively within a team environment. <br>
    `,
  },
];
