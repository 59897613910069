import template from "bundle-text:./index.html";
import { Career } from "./components/career";

export const About = () => {
  setTimeout(() => {
    ("use strict");
    // Inject careers
    var careersContainer = document.querySelector(".career-timeline");
    const careerContent = Career();
    var careers = new DOMParser().parseFromString(careerContent, "text/html");
    careersContainer.appendChild(careers.querySelector("ul"));

    // define variables
    var items = document.querySelectorAll(".career-timeline li");

    // check if an element is in viewport
    // http://stackoverflow.com/questions/123999/how-to-tell-if-a-dom-element-is-visible-in-the-current-viewport
    function isElementInViewport(el) {
      var rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    }

    function callbackFunc() {
      Array.from(document.getElementsByClassName("experience-years")).forEach(
        (e) => (e.innerHTML = new Date().getFullYear() - 2008),
      );
      for (var i = 0; i < items.length; i++) {
        if (isElementInViewport(items[i])) {
          items[i].classList.add("in-view");
        }
      }
    }

    // listen for events
    window.addEventListener("load", callbackFunc);
    window.addEventListener("resize", callbackFunc);
    window.addEventListener("scroll", callbackFunc);
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener("click", function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute("href")).scrollIntoView({
          behavior: "smooth",
        });
      });
    });
  }, 0);

  return template;
};

export default About;
